import React from 'react';
import { useEffect, useState } from 'react';
import './App.css';

function App() {
 
  return (
    <main>
      <h1> 😎 Roshan Kr Soni </h1>
   <p class="desc"> I am a <b>full-stack</b> web developer working on <b>ReactJs/Angular</b>, <b> ReactNative</b>, <b> MongoDB/PostgreSQL</b> & <b>Node.js</b>. Having a great passion in both back-end and front-end development,
   I can create API's and decent UI's. I have worked on real life projects and find myself capable enough to handle pressure 
   well. <br /> Team work and quick learner describes me really well. Apart from all this I love gaming, watching anime, sports etc. <br /> 
   I am a music lover ♫. </p>
      
    <p> 📱 +918340662031 / <b>roshankrsoni1@gmail.com </b></p> 
 

    </main>
  );
}

export default App;
